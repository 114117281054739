import React from 'react'
import {
  Button,
  Box,
  Checkbox,
  Radio,
  RadioGroup,
  TextField,
  FormControl,
  Select,
  Divider,
  Typography,
  FormControlLabel,
  Theme,
  makeStyles,
  MenuItem,
  FormGroup,
} from '@material-ui/core'
import { RichMenuType, RedirectUriType, gaType, RichMenuTypeKey } from '../../../../features/enum'
import { APP_SCREEN_LIST, APP_GA_LIST } from '../../../../constants/richmenu'
import { UploadFileName } from '../../../molecules'
import { hooks, api } from '../../../../libs'
import { COLORS, RICHMENU } from '../../../../constants'
import { useDispatch } from 'react-redux'
import { setNotice, removeNotice } from '../../../../stores/notice/notice.action'
import { ModalsContext } from '../../../../contexts'
import querystring from 'query-string'
import { SubMenuList } from './SubMenuList'
import { clsx } from 'clsx'

const useStyles = makeStyles((theme: Theme) => ({
  media: {
    height: 0,
    paddingTop: '67%',
    marginBottom: 10,
  },
  uploadImgBtnWrapper: {
    justifyContent: 'center',
    paddingTop: 10,
  },
  uploadImgBtn: {
    borderRadius: theme.spacing(5),
    padding: '6px 25px',
  },
  listItem: {
    display: 'block',
  },
  listItemHeaderGroup: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inputURLWrapper: {
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: `20px 20px 20px 20px`,
    position: 'relative',
  },
  formControl: {
    paddingBottom: 20,
    maxWidth: '100%',
  },
  formControlMaxWidth: {
    width: '100%',
    paddingLeft: theme.spacing(4),
  },
  uploadPDFButton: {
    display: 'block',
    marginLeft: 'auto',
    marginTop: 5,
  },
  fileNameWrapper: {
    width: 'auto',
    padding: 0,
  },
  divider: {
    'background-color': 'rgba(0, 0, 0, 0)',
  },
  uploadMaterialButton: {
    display: 'block',
    marginLeft: 'auto',
    marginTop: 4,
  },
  materialType: {
    fontSize: 12,
    color: COLORS.CXD_GRAY_600,
  },
  disableLabel: {
    marginRight: 0,
  },
  typeName: {
    paddingBottom: 12,
  },
  radioErr: {
    '&.Mui-checked': {
      color: COLORS.RED_1,
    },
  },
  errMsg: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: COLORS.RED_1,
  },
  disabled: {
    '& .disabled': {
      pointerEvents: 'none',
    },
    '& .disabled-input': {
      color: COLORS.CXD_GRAY_400,
      '& .MuiInputBase-root': {
        color: COLORS.CXD_GRAY_400,
      },
    },
    '& .disabled-radio': {
      '& .MuiIconButton-label': {
        color: COLORS.CXD_GRAY_400,
      },
    },
  },
  disabledSubMenuRadio: {
    pointerEvents: 'none',
    '& .MuiIconButton-label': {
      color: COLORS.CXD_GRAY_400,
    },
  },
  disabledGaTrackingId: {
    display: 'none',
  },
}))

const NOT_SET_STATUS = '遷移先を設定しない '
const DESTINATION = '遷移先'
const SELECT_SCREEN_NAME = '画面名で選択する'
const ENTER_URL = 'URLを入力する'
const ENTER_TEXT = 'テキストを発話する'
const ATTACH_MATERIAL = 'ファイルを添付する'
const ATTACH_MATERIAL_TYPE = '（PDF,PNG,JPG）'
const ATTACH = '添付する'
const GA_TRACKING_ID = 'GAトラッキングID'
const SELECT = '選択する'
const INPUT = '入力する'
const SUBMENU = 'サブメニューを設定する'

export const RichmenuAreaInput = (props: any) => {
  const { status, onChange, value, areaIndex, errors, setError, clearErrors, subMenu, getValues } = props
  const classes = useStyles()
  const currentGAList = APP_GA_LIST[RichMenuTypeKey[status]]
  const currentScreenList = APP_SCREEN_LIST[RichMenuTypeKey[status]]
  const [areaData, setAreaData] = React.useState({
    redirectUri: currentScreenList[0],
    redirectUriType: RedirectUriType.SELECT,
    type: 'uri',
    uri: currentGAList[0],
    isDisabled: false,
    text: '',
    data: '',
  })
  const dispatch = useDispatch()

  const [redirectUriSelectBox, setRedirectUriSelectBox] = React.useState(currentScreenList[0])
  const [redirectUriInput, setRedirectUriInput] = React.useState('')
  const [messageInput, setMessageInput] = React.useState('')
  const [materialFileName, setMaterialFileName] = React.useState('')
  const [subMenuId, setSubMenuId] = React.useState('')
  const [gaRadio, setGARadio] = React.useState(gaType.SELECT)
  const [gaSelectBox, setGASelectBox] = React.useState(currentGAList[0])
  const [gaInput, setGAInput] = React.useState('')
  const [uploading, setUploading] = React.useState(false)

  const { accessToken } = hooks.useAuth()
  const { tenantId } = hooks.useTenant()
  const { appendModal, closeAllModals } = React.useContext(ModalsContext)

  React.useEffect(() => {
    onChange(areaData)
  }, [areaData])

  React.useEffect(() => {
    if (value) {
      setAreaData(value)
      if (value.redirectUriType === RedirectUriType.SELECT) {
        setRedirectUriSelectBox(value.redirectUri)
      } else if (value.redirectUriType === RedirectUriType.INPUT) {
        setRedirectUriInput(value.redirectUri)
      } else if (value.redirectUriType === RedirectUriType.FILE) {
        setMaterialFileName(decodeURIComponent(value.redirectUri))
      } else if (value.redirectUriType === RedirectUriType.TEXT) {
        setMessageInput(value?.text ? value.text.toString() : '')
      } else if (value.redirectUriType === RedirectUriType.SUBMENU) {
        const data = querystring.parse(value.data)
        setSubMenuId(data?.subMenuId ? data.subMenuId.toString() : '')
      }

      const gatag = value.uri
      const isInDefaultGAList = currentGAList.includes(gatag)
      if (isInDefaultGAList) {
        setGARadio(gaType.SELECT)
        setGASelectBox(gatag)
      } else {
        setGARadio(gaType.INPUT)
        setGAInput(gatag)
      }

      if (value.data) {
      }
    }
  }, [value])

  const clearInvalidMaterialErr = () => {
    return clearErrors(`richmenuData[${RichMenuTypeKey[status]}].areas[${areaIndex - 1}].action.redirectUri`)
  }

  const handleRadioChange = (e: any) => {
    if (errors?.action.redirectUri?.type === 'manual') {
      clearInvalidMaterialErr()
    }
    let dataForm = {}
    if (+e.target.value === RedirectUriType.SELECT) {
      dataForm = {
        redirectUriType: +e.target.value,
        redirectUri: redirectUriSelectBox,
        type: 'uri',
        data: '',
        text: '',
      }
    } else if (+e.target.value === RedirectUriType.INPUT) {
      dataForm = {
        redirectUriType: +e.target.value,
        redirectUri: redirectUriInput,
        type: 'uri',
        data: '',
        text: '',
      }
    } else if (+e.target.value === RedirectUriType.FILE) {
      dataForm = {
        redirectUriType: +e.target.value,
        redirectUri: materialFileName,
        type: 'uri',
        data: '',
        text: '',
      }
    } else if (+e.target.value === RedirectUriType.TEXT) {
      dataForm = {
        redirectUriType: +e.target.value,
        redirectUri: '',
        type: 'message',
        data: '',
        text: messageInput,
      }
    } else if (+e.target.value === RedirectUriType.SUBMENU) {
      dataForm = {
        redirectUriType: +e.target.value,
        redirectUri: '',
        type: 'postback',
        data: subMenuId ? querystring.stringify({ action: 'subMenu', subMenuId, tenantId }) : '',
        text: undefined,
      }
    }

    setAreaData(previous => ({
      ...previous,
      ...dataForm,
    }))
  }

  const handleGARadioChange = (e: any) => {
    setGARadio(+e.target.value)
    let dataForm = {}
    if (+e.target.value === gaType.SELECT) {
      dataForm = {
        uri: gaSelectBox,
      }
    } else if (+e.target.value === gaType.INPUT) {
      dataForm = {
        uri: gaInput,
      }
    }

    setAreaData(previous => ({
      ...previous,
      ...dataForm,
    }))
  }

  const handleUriSelectBoxChange = (e: any) => {
    const valueBox = e.target.value
    setRedirectUriSelectBox(valueBox)
    setAreaData(previous => ({
      ...previous,
      redirectUri: valueBox,
    }))
  }

  const handleUriInputChange = (e: any) => {
    const valueInput = e.target.value
    setRedirectUriInput(valueInput)
    setAreaData({
      ...areaData,
      redirectUri: valueInput,
    })
  }

  const handleMessageInputChange = (e: any) => {
    const valueInput = e.target.value
    setMessageInput(valueInput)
    setAreaData({
      ...areaData,
      text: valueInput,
    })
  }

  const checkMaterialValidType = (type: any) => {
    return type === 'application/pdf' || type === 'image/jpg' || type === 'image/jpeg' || type === 'image/png'
  }

  const handleClickMaterial = (e: any) => {
    e.target.value = ''
  }

  const handleUploadMaterial = (e: any) => {
    const file = (e.target.files as FileList)[0]
    if (file) {
      if (checkMaterialValidType(file.type)) {
        const fileName = file.name.replace(/ /g, '+')
        setUploading(true)
        setMaterialFileName(fileName)
        dispatch(
          api.postFileThunk(
            `rich-menu/materials/upload?path=${encodeURIComponent(fileName)}`,
            accessToken,
            tenantId,
            file,
            file.type,
          ),
        )
          .then(() => {
            setAreaData({
              ...areaData,
              redirectUri: file.name,
            })
            clearInvalidMaterialErr()
            dispatch(removeNotice(RICHMENU.UPLOAD_MATERIAL_FAILED_MSG))
          })
          .catch(() => {
            dispatch(setNotice(RICHMENU.UPLOAD_MATERIAL_FAILED_MSG))
          })
          .finally(() => {
            setUploading(false)
          })
      } else {
        setError(`richmenuData[${RichMenuTypeKey[status]}].areas[${areaIndex - 1}].action.redirectUri`, {
          type: 'manual',
          message: RICHMENU.INVALID_MAERIAL_MSG,
        })
        setMaterialFileName('')
        setAreaData({
          ...areaData,
          redirectUri: '',
        })
      }
    }
  }

  const handleGAInputChange = (e: any) => {
    const valueInput = e.target.value
    setGAInput(valueInput)
    setAreaData({
      ...areaData,
      uri: valueInput,
    })
  }

  const handleGASelectBoxChange = (e: any) => {
    const valueBox = e.target.value
    setGASelectBox(valueBox)
    setAreaData({
      ...areaData,
      uri: valueBox,
    })
  }

  const handleAreaDisableChange = () => {
    const temp = !areaData.isDisabled
    if (errors?.action.redirectUri?.type === 'manual') {
      clearInvalidMaterialErr()
    }
    setAreaData({
      ...areaData,
      isDisabled: temp,
    })
  }

  return (
    <>
      <Box display="flex" className={classes.listItemHeaderGroup}>
        <Box>
          <img alt="richmenu-position" src={`../assets/images/richmenu-${areaIndex}.png`} />
          {RichMenuType.REGIST === status && (
            <img alt="richmenu-position" src={`../assets/images/regist-richmenu-symbol.png`} />
          )}
        </Box>
        <Box>
          <FormGroup>
            <FormControlLabel
              checked={!!areaData.isDisabled}
              onChange={handleAreaDisableChange}
              className={`disabled disabled-checkbox ${classes.disableLabel}`}
              control={<Checkbox color="primary" />}
              label={NOT_SET_STATUS}
            />
          </FormGroup>
        </Box>
      </Box>
      <Box className={classes.inputURLWrapper}>
        <Box>
          <Typography className={classes.typeName}>{DESTINATION}</Typography>
          <Box pr={1}>
            <RadioGroup name="redirect-link" value={areaData.redirectUriType} onChange={handleRadioChange}>
              <FormControl className={classes.formControl}>
                <FormControlLabel
                  value={RedirectUriType.SELECT}
                  control={
                    <Radio
                      color={'primary'}
                      className={`${errors?.action?.hasOwnProperty('redirectUri') && classes.radioErr}`}
                    />
                  }
                  label={SELECT_SCREEN_NAME}
                  disabled={!!areaData.isDisabled}
                  className="disabled disabled-radio"
                />
                <FormControl variant="outlined" className={classes.formControlMaxWidth}>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    className="disabled disabled-input"
                    disabled={areaData.redirectUriType !== RedirectUriType.SELECT || !!areaData.isDisabled}
                    value={redirectUriSelectBox}
                    onChange={handleUriSelectBoxChange}
                  >
                    {currentScreenList.map((val: string, index: number) => {
                      return (
                        <MenuItem key={index} value={val}>
                          {val.substring(1)}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </FormControl>
              <FormControl className={classes.formControl}>
                <FormControlLabel
                  value={RedirectUriType.INPUT}
                  control={
                    <Radio
                      color={'primary'}
                      className={`${errors?.action.hasOwnProperty('redirectUri') && classes.radioErr}`}
                    />
                  }
                  label={ENTER_URL}
                  disabled={!!areaData.isDisabled}
                  className="disabled disabled-radio"
                />
                <TextField
                  placeholder="https://tokyu"
                  className={`${classes.formControlMaxWidth} disabled disabled-input`}
                  variant="outlined"
                  disabled={areaData.redirectUriType !== RedirectUriType.INPUT || !!areaData.isDisabled}
                  value={redirectUriInput}
                  onChange={handleUriInputChange}
                  error={
                    areaData.redirectUriType === RedirectUriType.INPUT && errors?.action.hasOwnProperty('redirectUri')
                  }
                  helperText={
                    errors?.action.hasOwnProperty('redirectUri') &&
                    areaData.redirectUriType === RedirectUriType.INPUT &&
                    errors?.action.redirectUri?.message
                  }
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <FormControlLabel
                  value={RedirectUriType.FILE}
                  control={
                    <Radio
                      color={'primary'}
                      className={`${errors?.action.hasOwnProperty('redirectUri') && classes.radioErr}`}
                    />
                  }
                  className="disabled disabled-radio"
                  label={
                    <Typography>
                      {ATTACH_MATERIAL}
                      <span className={classes.materialType}>{ATTACH_MATERIAL_TYPE}</span>
                    </Typography>
                  }
                  disabled={!!areaData.isDisabled}
                />
                <Box pl={4}>
                  <UploadFileName
                    fileName={materialFileName ? materialFileName : RICHMENU.NOT_SELECT_MATERIAL_TITLE}
                    loading={uploading}
                    error={
                      areaData.redirectUriType === RedirectUriType.FILE && errors?.action.hasOwnProperty('redirectUri')
                    }
                    type={2}
                    errMsg={errors?.action?.redirectUri?.type === 'manual' && errors?.action?.redirectUri?.message}
                  />
                </Box>
                <Button
                  variant="contained"
                  component="label"
                  color="primary"
                  className={`${classes.uploadMaterialButton} disabled disabled-material-btn`}
                  disabled={areaData.redirectUriType !== RedirectUriType.FILE || !!areaData.isDisabled}
                  onChange={(e: any) => handleUploadMaterial(e)}
                >
                  {ATTACH}
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    accept="application/pdf, .jpg, .jpeg, .png"
                    onClick={(e: any) => handleClickMaterial(e)}
                  />
                </Button>
              </FormControl>
              <FormControl className={classes.formControl}>
                <FormControlLabel
                  value={RedirectUriType.TEXT}
                  control={
                    <Radio
                      color={'primary'}
                      className={`${errors?.action.hasOwnProperty('text') && classes.radioErr}`}
                    />
                  }
                  label={ENTER_TEXT}
                  disabled={!!areaData.isDisabled}
                  className="disabled disabled-radio"
                />
                <TextField
                  className={`${classes.formControlMaxWidth} disabled disabled-input`}
                  variant="outlined"
                  disabled={areaData.redirectUriType !== RedirectUriType.TEXT || !!areaData.isDisabled}
                  value={messageInput}
                  onChange={handleMessageInputChange}
                  error={areaData.redirectUriType === RedirectUriType.TEXT && errors?.action.hasOwnProperty('text')}
                  helperText={
                    errors?.action.hasOwnProperty('text') &&
                    areaData.redirectUriType === RedirectUriType.TEXT &&
                    errors?.action.text?.message
                  }
                  multiline
                  inputProps={{ maxLength: 300 }}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <FormControlLabel
                  value={RedirectUriType.SUBMENU}
                  control={
                    <Radio
                      color={'primary'}
                      className={`${errors?.action.hasOwnProperty('data') && classes.radioErr}`}
                    />
                  }
                  label={SUBMENU}
                  disabled={!!areaData.isDisabled}
                  className={`${
                    (!subMenu || subMenu.length === 0) && classes.disabledSubMenuRadio
                  } disabled disabled-radio`}
                />
                <SubMenuList
                  subMenu={subMenu}
                  subMenuId={subMenuId}
                  setSubMenuId={setSubMenuId}
                  areaData={areaData}
                  setAreaData={setAreaData}
                />
                {areaData.redirectUriType === RedirectUriType.SUBMENU && errors?.action.hasOwnProperty('data') && (
                  <span className={classes.errMsg}>{errors?.action?.data?.message}</span>
                )}
              </FormControl>
            </RadioGroup>
          </Box>
        </Box>
        <Divider className={classes.divider} />
        <Box
          className={
            areaData.redirectUriType === RedirectUriType.TEXT || areaData.redirectUriType === RedirectUriType.SUBMENU
              ? classes.disabled
              : ''
          }
        >
          <Typography className={clsx(classes.typeName, classes.disabledGaTrackingId)}>{GA_TRACKING_ID}</Typography>
          <Box pr={1} className={classes.disabledGaTrackingId}>
            <RadioGroup name="ga-tracking" value={gaRadio} onChange={handleGARadioChange}>
              <FormControl className={classes.formControl}>
                <FormControlLabel
                  value={gaType.SELECT}
                  control={
                    <Radio color="primary" className={`${errors?.action.hasOwnProperty('uri') && classes.radioErr}`} />
                  }
                  label={SELECT}
                  disabled={!!areaData.isDisabled}
                  className="disabled disabled-radio"
                />
                <FormControl variant="outlined" className={classes.formControlMaxWidth}>
                  <Select
                    disabled={gaRadio !== gaType.SELECT || !!areaData.isDisabled}
                    value={gaSelectBox}
                    onChange={handleGASelectBoxChange}
                    labelId="demo-simple-select-outlined-label"
                    className="disabled disabled-input"
                  >
                    {currentGAList.map((val: string, index: number) => {
                      return (
                        <MenuItem key={index} value={val}>
                          {val}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </FormControl>
              <FormControl>
                <FormControlLabel
                  value={gaType.INPUT}
                  control={
                    <Radio color="primary" className={`${errors?.action.hasOwnProperty('uri') && classes.radioErr}`} />
                  }
                  label={INPUT}
                  disabled={!!areaData.isDisabled}
                  className="disabled disabled-radio"
                />
                <TextField
                  disabled={gaRadio !== gaType.INPUT || !!areaData.isDisabled}
                  variant="outlined"
                  placeholder="CardA"
                  value={gaInput}
                  onChange={handleGAInputChange}
                  className={`${classes.formControlMaxWidth} disabled disabled-input`}
                  error={gaRadio === gaType.INPUT && errors?.action.hasOwnProperty('uri')}
                  helperText={
                    gaRadio === gaType.INPUT && errors?.action.hasOwnProperty('uri') && errors?.action?.uri?.message
                  }
                />
              </FormControl>
            </RadioGroup>
          </Box>
        </Box>
      </Box>
    </>
  )
}
